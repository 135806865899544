import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import ApplicationCard from '../../components/Card';

const Index = ({ data }) => {
  const cards = data.allApplicationsJson.edges.map(edge => edge.node);

  return (
    <Layout bodyClass="application">
      <SEO title="Application" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Join Us</h1>
              <p>
                BAASIS LABS partnered with the best enterpreneurs from different companies to provide our startups with the best possible mentoring across all areas needed to build a successful company.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-8 pb-10 pt-md-10">
        <div className="row justify-content-center">
          <div className="card-deck">
            {cards.map(card => (
              <div key={ card.id } className="card col-12 col-md-6 col-lg-4 mb-2">
                <ApplicationCard card={ card } />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query {
    allApplicationsJson {
      edges {
        node {
          id
          header
          title
          description
          image
          link
          color
        }
      }
    }
  }
`;

export default Index;
