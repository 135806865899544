import React from 'react';
import { withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import Link from './Link';
import Collage from './Collage';

const CardInside = ({ card }) => (
  <>
    <CardImage card={ card } />
    <div className="card-header">
      <h3 style={{ color: '#02B8B3' }}>{ card.header }</h3>
      <h4>{ card.subheader }</h4>
    </div>
    <div className="card-body pt-0">
      <h5 className="card-title">{ card.title }</h5>
      <p className="card-text">{ card.description }</p>
    </div>
  </>
);

const CardImage = ({ card }) => {
  if (card.batchImage) {
    return (
      <div
        className={`card-batch-img-top mt-2 mb-2 ${card.batchImageClass}`}
        style={{
          backgroundImage: `url(${withPrefix(card.batchImage)})`,
        }} />
    );
  }
  if (card.startups) {
    const photos = card.startups.map(startup => ({ src: startup.image }));
    return <Collage photos={ photos } />;
  }
  return (
    <div
      className={`card-img-top mt-2 mb-2 ${card.imageClass}`}
      style={{
        backgroundImage: `url(${withPrefix(card.image)})`,
      }} />
  );
};

const Card = ({ card }) => {
  if (card.link) {
    return (
      <Link to={card.link}>
        <CardInside card={card} />
      </Link>
    );
  }
  return (
    <CardInside card={card} />
  );
};

CardImage.propTypes = {
  card: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
  ).isRequired,
};

CardInside.propTypes = {
  card: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
  ).isRequired,
};

Card.propTypes = {
  card: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
  ).isRequired,
};

export default Card;
